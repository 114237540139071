<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="回答时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="回答内容：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入回答内容"></el-input>
        </el-form-item>
        <el-form-item label="回答者：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="所属问题：" prop="target_content">
          <el-input v-model="form.target_content" placeholder="请输入问题"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="回答级别：" prop="comment_level">
          <el-select v-model="form.comment_level" placeholder="请选择回答级别">
            <el-option v-for="item in commentOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      commentOptions: [
        { name: "全部", id: 0 },
        { name: "一级回答", id: 1 },
        { name: "二级回答", id: 2 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    // onExport() {
    //   const postData = { ...this.form, page: this.page };
    //   return topicsExport(postData)
    //     .then()
    //     .catch(() => {});
    // },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>