<!--
 * @Author: dongjia
 * @Date: 2021-08-16 15:10:50
 * @LastEditTime: 2022-03-30 16:30:10
 * @LastEditors: aleaner
 * @Description: 回答管理列表页
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\views\comment-admin\list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <list-filter
      :filterForm="filterForm"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button
          v-if="['checking', 'uncheck'].includes(filterForm.tab)"
          size="small"
          @click="handleChecked"
        >
          通过
        </el-button>
        <el-button
          v-if="filterForm.tab === 'checking'"
          size="small"
          @click="handleNoChecked"
        >
          不通过
        </el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除
        </el-button>
        <el-button
          v-if="filterForm.tab === 'deleted'"
          type="success"
          size="small"
          @click="handleReduct"
        >
          还原
        </el-button>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :width="handleLabelWidth[filterForm.tab]"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button-group>
              <!-- <el-button
                v-if="!scope.row.black_status && filterForm.tab !== 'deleted'"
                :disabled="scope.row.is_admin === 1"
                size="small"
                @click="blackRow(scope.row)"
                type="text"
              >
                拉黑
              </el-button> -->
              <el-button
                v-if="scope.row.black_status && filterForm.tab !== 'deleted'"
                size="small"
                @click="cancelblackRow(scope.row)"
                type="text"
              >
                移出
              </el-button>
              <el-button
                v-if="filterForm.tab === 'checked'"
                size="small"
                @click="replyRow(scope.row)"
                type="text"
              >
                回复
              </el-button>
              <el-button
                v-if="['checking', 'uncheck'].includes(filterForm.tab)"
                size="small"
                @click="checkedRow(scope.row)"
                type="text"
              >
                通过
              </el-button>
              <el-button
                v-if="filterForm.tab === 'checking'"
                size="small"
                @click="noCheckedRow(scope.row)"
                type="text"
              >
                不通过
              </el-button>
              <el-button
                v-if="filterForm.tab === 'checked'"
                size="small"
                @click="setTopRow(scope.row)"
                type="text"
                :disabled="scope.row.level_text !== '一级'"
              >
                {{ scope.row.is_top ? '取消置顶' : '置顶' }}
              </el-button>
              <el-button
                v-if="
                  ['checked', 'checking', 'uncheck'].includes(filterForm.tab)
                "
                size="small"
                @click="detailRow(scope.row)"
                type="text"
              >
                详情
              </el-button>
              <el-button
                v-if="filterForm.tab === 'deleted'"
                size="small"
                @click="ReductRow(scope.row)"
                type="text"
              >
                还原
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[theadKey]">
          <el-table-column
            :show-overflow-tooltip="th.prop !== 'target_text'"
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-if="th.prop === 'is_show'"
                v-model="scope.row[th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
              >
              </el-checkbox>
              <el-checkbox
                v-else-if="th.prop === 'is_top'"
                :disabled="scope.row.comment_level > 0"
                v-model="scope.row[th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
              >
              </el-checkbox>
              <div class="black-member" v-else-if="th.prop === 'nickname'">
                <p>{{ scope.row[th.prop] | placeholder }}</p>
                <div class="black-tag" v-if="scope.row.black_status">
                  黑名单
                </div>
              </div>
              <el-popover
                v-else-if="th.prop === 'target_text'"
                placement="bottom"
                width="374"
                trigger="hover"
              >
                <template v-if="scope.row.target_text">
                  <div v-html="scope.row.target_text.content"></div>
                  <!-- popover图片 -->
                  <div
                    v-if="
                      scope.row.target_text &&
                      scope.row.target_text.images &&
                      scope.row.target_text.images.length
                    "
                    :style="{
                      marginTop: scope.row.target_text.content ? '13px' : '',
                    }"
                    class="popover-imgs"
                  >
                    <el-image
                      v-for="(img, index) in scope.row.target_text.images"
                      class="popover-img cursor-pointer"
                      :style="{
                        marginTop: index >= 3 ? '16px' : '',
                      }"
                      :src="fixImageUrl(img.url)"
                      @click="
                        $previewImage(scope.row.target_text.images, index)
                      "
                      fit="cover"
                      :key="index"
                    >
                    </el-image>
                  </div>
                  <!-- popover视频 -->
                  <div
                    class="popover-video"
                    v-if="
                      scope.row.target_text.video &&
                      scope.row.target_text.video.thumbnail
                    "
                    :style="{
                      marginTop: scope.row.target_text.content ? '13px' : '',
                    }"
                  >
                    <el-image
                      class="video-thumbnail"
                      :src="fixImageUrl(scope.row.target_text.video.thumbnail)"
                      fit="cover"
                    >
                    </el-image>
                    <div
                      class="video-mask cursor-pointer"
                      @click="handlePlay(scope.row.target_text.video)"
                    >
                      <img
                        class="video-play"
                        src="@/base/assets/images/media/video-play@2x.png"
                      />
                    </div>
                  </div>
                  <!-- 帖子内容 -->
                  <div
                    class="list-contents"
                    slot="reference"
                    v-html="scope.row.target_text.content"
                    v-if="scope.row.target_text.content"
                  ></div>
                  <div class="list-contents" slot="reference" v-else>
                    <el-image
                      v-if="scope.row.target_text.images.length"
                      class="list-icon cursor-pointer"
                      :src="fixImageUrl(scope.row.target_text.images[0].url)"
                      @click="$previewImage(scope.row.target_text.images)"
                      fit="cover"
                    >
                    </el-image>
                    <div
                      class="content-video"
                      v-if="scope.row.target_text.video.thumbnail"
                    >
                      <el-image
                        class="video-thumbnail"
                        :src="
                          fixImageUrl(scope.row.target_text.video.thumbnail)
                        "
                        fit="cover"
                      ></el-image>
                      <div
                        class="video-mask cursor-pointer"
                        @click="handlePlay(scope.row.target_text.video)"
                      >
                        <img
                          class="video-play"
                          src="@/base/assets/images/media/video-play@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </el-popover>
              <!-- <el-image v-else-if="th.prop === 'icon'" class="list-icon cursor-pointer"
                :src="fixImageUrl(scope.row[th.prop])" @click="$previewImage([scope.row[th.prop]])">
              </el-image> -->
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <!-- <AddPost v-model="openAddPost" :Data="PostConfig" :handleType="changeRowType"
      :updateList="refreshList"></AddPost> -->
    <NoCheckedDialog
      v-model="openNoCheckedDialog"
      :Data="noCheckData"
      :updateList="refreshList"
    />
    <ReplyDialog
      v-model="openReplyDialog"
      :Data="replyData"
      :updateList="refreshList"
    />
    <VideoDialog />
    <!-- 拉黑弹窗 -->
    <SetMemberBlackDialog
      v-model="showBlackDialog"
      :id="current_id"
      blackType="comment"
      :updateList="refreshList"
    />
  </div>
</template>

<script>
import listFilter from '@/modules/consult/components/ConsultContentComment/ListFilter.vue'
import Pagination from '@/base/components/Default/Pagination'
import NoCheckedDialog from '@/modules/consult/components/ConsultContentComment/NoCheckedDialog.vue'
import ReplyDialog from '@/modules/consult/components/ConsultContentComment/ReplyDialog.vue'
import VideoDialog from '@/base/components/Preview/VideoViewer'
import SetMemberBlackDialog from '@/modules/consult/components/common/SetMemberBlackDialog.vue'

import {
  commentList,
  updateShow,
  updateTop,
  softDelete,
  Delete,
  putBack,
  check,
} from '@/modules/consult/api/consult-content-comment/list.js'
import { setBlack } from '@/modules/consult/api/black-member.js'
export default {
  data() {
    return {
      tabArr: [
        { label: '已审核', name: 'checked' },
        { label: '待审核', name: 'checking', badge: 1 },
        { label: '未通过', name: 'uncheck' },
        { label: '回收站', name: 'deleted' },
      ],
      loading: false, //加载
      thead: {
        checked: [
          { label: '回答者', prop: 'nickname', minWidth: 150 },
          { label: '内容', prop: 'content', minWidth: 160 },
          { label: '回答级别', prop: 'level_text', minWidth: 80 },
          { label: '所属问题', prop: 'target_content', minWidth: 160 },
          {
            label: '点赞数',
            prop: 'likes_count',
            minWidth: 100,
            sortable: true,
          },
          // {
          //   label: '举报数',
          //   prop: 'complaints_count',
          //   minWidth: 100,
          //   sortable: true,
          // },
          {
            label: '回答时间',
            prop: 'create_time',
            minWidth: 160,
            sortable: true,
          },
          {
            label: '置顶',
            prop: 'is_top',
            minWidth: 60,
          },
          // {
          //   label: "显示",
          //   prop: "is_show",
          //   minWidth: 60,
          // },
        ],
        unchecked: [
          { label: '作者', prop: 'nickname', minWidth: 150 },
          { label: '内容', prop: 'content', minWidth: 160 },
          { label: '回答级别', prop: 'level_text', minWidth: 110 },
          { label: '所属问题', prop: 'target_content', minWidth: 160 },
          {
            label: '编辑时间',
            prop: 'create_time',
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      //筛选对象
      filterForm: {
        content: '', // 帖子内容
        nickname: '', // 创建者名称
        target_content: '', // 回答名称
        comment_level: 0, // 回答等级
        start_time: -1, //更新开始时间
        end_time: -1, //更新结束时间
        tab: 'checking', //当前筛选tab
        page_size: 15, //每页多少条数据
        topic_id: '', // 话题id
      },
      // 筛选缓存
      filters: {
        checked: null,
        checking: null,
        uncheck: null,
        deleted: null,
      },
      handleLabelWidth: {
        checked: 210,
        checking: 260,
        uncheck: 210,
        deleted: 100,
      },
      pageData: {
        page_size: 15,
        tab: 'checking',
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      PostConfig: {},
      noCheckData: {},
      replyData: {},
      current_id: '',
      showBlackDialog: false,
      // openAddPost: false, // 开启设置弹窗
      openReplyDialog: false,
      openNoCheckedDialog: false,
      changeRowType: 'change',
    }
  },
  computed: {
    theadKey() {
      return ['checking', 'uncheck', 'deleted'].includes(this.filterForm.tab)
        ? 'unchecked'
        : this.filterForm.tab
    },
  },
  methods: {
    // 获取列表
    getCommentList(pageData) {
      this.tbody = []
      this.pageData = {}
      this.loading = true
      commentList(pageData)
        .then((res) => {
          const { data } = res
          console.log(data)
          this.tbody = data.data
          this.pageData = data
          this.selectedMembers = data.user_ids
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 回复回答
    replyRow(row) {
      this.replyData = {
        id: row.id,
        content: '',
      }
      this.openReplyDialog = true
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab !== 'deleted') {
          this.$msgbox
            .confirm('确定要将选中数据移入回收站吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        } else {
          this.$msgbox
            .confirm('确定要将选中数据永久删除吗？', '提示', {
              type: 'error',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        }
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab !== 'deleted') {
        this.$msgbox
          .confirm('确定要将此数据移入回收站吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.handleDelete({ id: row.id })
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('确定要将此数据永久删除吗？', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.handleDelete({ id: row.id })
          })
          .catch((err) => {})
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true
      if (this.filterForm.tab !== 'deleted') {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm('确定要将选中数据还原吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr })
          })
          .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm('确定要将此数据还原吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.Reduct({ id: row.id })
        })
        .catch((err) => {})
    },
    // 还原数据
    Reduct(id) {
      this.loading = true
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg)
          this.refreshList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 点击通过
    checkedRow(row) {
      this.$msgbox
        .confirm('确定要通过该数据吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.loading = true
          check({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg)
              this.refreshList()
            })
            .catch((err) => {
              this.refreshList()
            })
        })
        .catch((err) => {})
    },
    // 批量通过
    handleChecked() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm('确定要通过这些数据吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.loading = true
            check({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg)
                this.refreshList()
              })
              .catch((err) => {
                this.refreshList()
              })
          })
          .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击不通过
    noCheckedRow(row) {
      this.noCheckData = {
        id: row.id,
        reason: '',
      }
      this.openNoCheckedDialog = true
    },
    // 批量不通过
    handleNoChecked() {
      if (this.selectArr.length) {
        this.noCheckData = {
          id: this.selectArr,
          reason: '',
        }
        this.openNoCheckedDialog = true
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: 'groupDetail',
        params: { id: row.id },
      })
    },
    // 拉黑数据
    blackRow(row) {
      this.showBlackDialog = true
      this.current_id = row.id
    },
    // 取消拉黑
    cancelblackRow(row) {
      this.loading = true
      setBlack({ id: row.id, is_black: 0 }, 'comment')
        .then((res) => {
          this.$message.success(res.msg)
          this.refreshList()
        })
        .catch((err) => {
          this.refreshList()
        })
    },
    // 置顶帖子
    setTopRow(row) {
      if (row.is_top) {
        this.$msgbox
          .confirm('确定要取消置顶吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            updateTop({
              id: row.id,
              is_top: row.is_top ? 0 : 1,
            }).then((res) => {
              this.refreshList()
            })
          })
          .catch((err) => {})
      } else {
        updateTop({
          id: row.id,
          is_top: row.is_top ? 0 : 1,
        }).then((res) => {
          this.refreshList()
        })
      }
    },
    // 点击详情
    detailRow(row) {
      this.$router.push({
        name: 'ConsultContentCommentDetail',
        params: { id: row.id },
      })
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name]
      } else {
        let form = {
          content: '', // 帖子内容
          nickname: '', // 创建者名称
          target_content: '', // 回答名称
          comment_level: 0, // 回答等级
          start_time: -1, //更新开始时间
          end_time: -1, //更新结束时间
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
          topic_id: this.filterForm.topic_id, // 话题id
        }
        this.filterForm = form
      }
      this.getCommentList(this.filterForm)
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.getCommentList(this.filterForm)
    },
    // 筛选回调
    ok(e) {
      const pageData = {}
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getCommentList({ ...this.filterForm, ...pageData })
    },
    // 刷新列表
    refreshList() {
      this.getCommentList({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {}
      data.id = e.id
      data[key] = e[key]
      this.loading = true
      if (key == 'is_top') {
        // 更新推荐状态
        updateTop(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      } else if (key === 'is_show') {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size
      const pageData = { ...this.filterForm, ...e }
      this.getCommentList(pageData)
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit('media/setIsOpenMediaVideoDialog', true)
      this.$store.commit('media/setDialogDetail', data)
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    // 如果是从活动详情，回答管理进来的
    if (this.$route.params.topic_id) {
      this.filterForm.topic_id = this.$route.params.topic_id
    }
    this.getCommentList(this.filterForm) //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    NoCheckedDialog,
    ReplyDialog,
    VideoDialog,
    SetMemberBlackDialog,
  },
}
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.black-member {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.black-member {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list-contents {
  @include clamp(1);
}
.popover-imgs {
  display: flex;
  flex-wrap: wrap;
  .popover-img {
    width: 90px;
    height: 90px;
    margin-right: 16px;
    border-radius: 4px;
  }
}
.content-video {
  position: relative;
  width: 105px;
  height: 60px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}
.popover-video {
  position: relative;
  width: 347px;
  height: 191px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 46px;
      height: 46px;
    }
  }
}
</style>
